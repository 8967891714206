
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "AddCompanyModal",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const addCompanyModalRef = ref(null);

    const modalHide = () => {
      hideModal(addCompanyModalRef.value);
    };

    return {
      submitButtonRef,
      modalRef,
      addCompanyModalRef,
      modalHide,
    };
  },
});
