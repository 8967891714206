<template>
  <ContentCard :card-height="37">
    <template v-slot:header>
      <h2 class="primary-text">Your Deal Terms Offer</h2>
    </template>
    <template v-slot:body>
      <div class="pt-5" v-loading="loading">
        <AddPitchAttributesButton
          v-if="!projectDealTerms.revenueShare"
          button-title="Basis for Revenue Share"
          logo="/media/images/small-plus-blue.png"
          :button-height="6"
        >
          <EditProfileDropdown
            :dropdown-menu-items="revenueShares"
            @item-clicked="onSelectRevenueShare"
          />
        </AddPitchAttributesButton>
        <HighlightsText
          class="mb-5"
          v-else
          :highlight="`Basis for Revenue Share: ${projectDealTerms.revenueShare.name}`"
          :container-height="6"
          :show-remove-icon="false"
        >
          <EditProfileDropdown
            :dropdown-menu-items="revenueShares"
            @item-clicked="onSelectRevenueShare"
          />
        </HighlightsText>
        <AddPitchAttributesButton
          v-if="!projectDealTerms.percentageRevenueShare"
          button-title="% Revenue Share"
          logo="/media/images/small-plus-blue.png"
          :button-height="6"
        >
          <EditProfileDropdown
            unit="%"
            :dropdown-menu-items="percentageRevenueShares"
            @item-clicked="onSelectPercentageRevenueShare"
          />
        </AddPitchAttributesButton>
        <HighlightsText
          class="mb-5"
          v-else
          :highlight="`% Revenue Share: ${
            projectDealTerms.percentageRevenueShare + '%'
          }`"
          :container-height="6"
          :show-remove-icon="false"
        >
          <EditProfileDropdown
            unit="%"
            :dropdown-menu-items="percentageRevenueShares"
            @item-clicked="onSelectPercentageRevenueShare"
          />
        </HighlightsText>
        <AddPitchAttributesButton
          v-if="!projectDealTerms.thresholdSubItem"
          button-title="Free Threshold"
          logo="/media/images/small-plus-blue.png"
          :button-height="6"
        >
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
          >
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="right"
              data-kt-menu-flip="center, top"
              v-for="(item, index) in projectThresholdItems"
              :key="index"
            >
              <div class="menu-link px-5">
                <span class="menu-title">{{ item.name }}</span>
                <span class="menu-arrow"></span>
              </div>

              <div class="menu-sub menu-sub-dropdown w-175px py-4">
                <div
                  v-for="subItem in item.thresholdSubItem"
                  :key="subItem.id"
                  class="menu-item px-3"
                >
                  <div
                    :class="['menu-link', 'px-5']"
                    @click="onSelectThreshold(subItem)"
                  >
                    <span>€{{ subItem.value.toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AddPitchAttributesButton>
        <HighlightsText
          class="mb-5"
          v-else
          :highlight="`Free Threshold: ${
            projectDealTerms.thresholdSubItem.thresholdItem.name
          } / €${projectDealTerms.thresholdSubItem.value.toLocaleString()}`"
          :container-height="6"
          :show-remove-icon="false"
        >
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
          >
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="right"
              data-kt-menu-flip="center, top"
              v-for="(item, index) in projectThresholdItems"
              :key="index"
            >
              <div class="menu-link px-5">
                <span class="menu-title">{{ item.name }}</span>
                <span class="menu-arrow"></span>
              </div>

              <div class="menu-sub menu-sub-dropdown w-175px py-4">
                <div
                  v-for="subItem in item.thresholdSubItem"
                  :key="subItem.id"
                  class="menu-item px-3"
                >
                  <div
                    :class="['menu-link', 'px-5']"
                    @click="onSelectThreshold(subItem)"
                  >
                    <span>€{{ subItem.value.toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HighlightsText>
        <AddPitchAttributesButton
          v-if="!projectDealTerms.equityShare"
          button-title="% Equity Share"
          logo="/media/images/small-plus-blue.png"
          :button-height="6"
        >
          <EditProfileDropdown
            unit="%"
            :dropdown-menu-items="equityShare"
            @item-clicked="onSelectEquityShare"
          />
        </AddPitchAttributesButton>
        <HighlightsText
          class="mb-5"
          v-else
          :highlight="`% Equity Share: ${projectDealTerms.equityShare + '%'}`"
          :container-height="6"
          :show-remove-icon="false"
        >
          <EditProfileDropdown
            unit="%"
            :dropdown-menu-items="equityShare"
            @item-clicked="onSelectEquityShare"
          />
        </HighlightsText>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import AddPitchAttributesButton from "./AddPitchAttributesButton";
import EditProfileDropdown from "../../expert/components/EditProfileDropdown";
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  getProjectDealTerms,
  getProjectThresholdItems,
  getRevenueShares,
  updateBasicRevenueShare,
  updateEquityShare,
  updateFreeThreshold,
  updatePercentageRevenueShare,
} from "../../api/founder.api";
import HighlightsText from "./HighlightsText";
import { MenuComponent } from "../../assets/ts/components";
export default {
  name: "DealTerms",
  components: {
    HighlightsText,
    EditProfileDropdown,
    AddPitchAttributesButton,
    ContentCard,
  },
  setup() {
    const revenueShares = ref([]);
    const loading = ref(false);
    const projectDealTerms = ref({
      equityShare: null,
      percentageRevenueShare: null,
      revenueShare: null,
      thresholdSubItem: null,
      thresholdValue: null,
    });
    const percentageRevenueShares = ref([
      1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, 13.0, 14.0,
      15.0, 16.0, 17.0, 18.0, 19.0, 20.0, 22.5, 25.0, 27.5, 30.0, 32.5, 35.0,
      37.5, 40.0, 42.5, 45.0, 47.5, 50.0, 52.5, 55.0, 57.5, 60.0, 62.5, 65.0,
    ]);
    const projectThresholdItems = ref([]);
    const equityShare = ref([
      1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, 13.0, 14.0,
      15.0, 16.0, 17.0, 18.0, 19.0, 20.0, 22.5, 25.0, 27.5, 30.0, 32.5, 35.0,
      37.5, 40.0, 42.5, 45.0, 47.5, 50.0, 52.5, 55.0, 57.5, 60.0, 62.5, 65.0,
    ]);

    onMounted(async () => {
      try {
        await fetchProjectDealTerms();
        await fetchRevenueShares();
        await fetchThresholdItems();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to get the data.",
          type: "error",
        });
      } finally {
        MenuComponent.reinitialization();
      }
    });

    const fetchRevenueShares = async () => {
      const { data } = await getRevenueShares();
      revenueShares.value = data.data;
    };

    const fetchProjectDealTerms = async () => {
      const { data } = await getProjectDealTerms();
      projectDealTerms.value.equityShare = data.data.equityShare;
      projectDealTerms.value.revenueShare = data.data.revenueShare;
      projectDealTerms.value.percentageRevenueShare =
        data.data.percentageRevenueShare;
      projectDealTerms.value.thresholdValue = data.data.equityShare;
      projectDealTerms.value.thresholdSubItem = data.data.thresholdSubItem;
    };

    const fetchThresholdItems = async () => {
      const { data } = await getProjectThresholdItems();
      projectThresholdItems.value = data.data;
    };

    const onSelectRevenueShare = async (revenueShare) => {
      try {
        loading.value = true;
        await updateBasicRevenueShare(revenueShare.id);
        await fetchProjectDealTerms();
        ElNotification({
          title: "Success",
          message: "Basic Revenue Share updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Basic Revenue not updated.",
          type: "error",
        });
      } finally {
        loading.value = false;
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    };

    const onSelectPercentageRevenueShare = async (percentageRevenueShare) => {
      try {
        loading.value = true;
        await updatePercentageRevenueShare(percentageRevenueShare);
        await fetchProjectDealTerms();
        ElNotification({
          title: "Success",
          message: "Percentage Revenue Share updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Percentage Revenue not updated.",
          type: "error",
        });
      } finally {
        loading.value = false;
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    };

    const onSelectThreshold = async (threshold) => {
      try {
        loading.value = true;
        await updateFreeThreshold(threshold.id);
        await fetchProjectDealTerms();
        ElNotification({
          title: "Success",
          message: "Free Threshold updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Free Threshold not updated.",
          type: "error",
        });
      } finally {
        loading.value = false;
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    };

    const onSelectEquityShare = async (percentageEquityShare) => {
      try {
        loading.value = true;
        await updateEquityShare(percentageEquityShare);
        await fetchProjectDealTerms();
        ElNotification({
          title: "Success",
          message: "Equity Share updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Equity Share not updated.",
          type: "error",
        });
      } finally {
        loading.value = false;
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    };

    return {
      revenueShares,
      percentageRevenueShares,
      projectDealTerms,
      loading,
      projectThresholdItems,
      equityShare,
      onSelectRevenueShare,
      onSelectPercentageRevenueShare,
      onSelectThreshold,
      onSelectEquityShare,
    };
  },
};
</script>

<style scoped></style>
