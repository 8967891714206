<template>
  <div
    class="primary-transparent-background text-center position-relative"
    :style="{ height: height }"
    v-on:dragover.prevent="handleDragOver"
    v-on:drop.prevent="handleDrop"
    v-on:dragleave.prevent="handleDragLeave"
  >
    <div
      class="position-absolute edit-icon-div cursor-pointer"
      @click="imageClicked"
    >
      <inline-svg
        class="edit-icon"
        src="media/icons/duotune/general/gen055.svg"
      />
    </div>
    <div
      class="position-absolute delete-icon-div cursor-pointer"
      @click="removeFile"
    >
      <inline-svg
        class="edit-icon"
        src="media/icons/duotune/general/gen040.svg"
      />
    </div>
    <div class="d-flex h-100 w-100">
      <img
        @click="viewFile"
        :src="image ? image : imageUrl"
        class="img cursor-pointer"
      />
      <input
        class="d-none"
        type="file"
        accept="image/*"
        ref="inputFileRef"
        @change="handleFileSelected"
      />
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from "vue";
import { ElNotification } from "element-plus";

export default {
  name: "LogoImage",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    buttonHeight: {
      type: Number,
      required: false,
    },
    attachmentType: {
      type: String,
      required: false,
    },
  },
  computed: {
    height: function () {
      return this.buttonHeight + "rem";
    },
  },
  setup(props, context) {
    const file = ref(null);
    const image = ref("");
    const inputFileRef = ref(null);

    onUnmounted(() => {
      if (image.value) {
        URL.revokeObjectURL(image.value);
      }
    });

    const viewFile = () => {
      if (props.imageUrl) {
        window.open(props.imageUrl, "_blank");
      }
    };

    const getImagePreview = (file) => {
      if (image.value) {
        URL.revokeObjectURL(image.value);
      }
      image.value = URL.createObjectURL(file);
    };

    const imageClicked = (e) => {
      inputFileRef.value.click();
    };

    const handleFileSelected = (event) => {
      handleDrop({
        dataTransfer: {
          files: event.target.files,
        },
      });
    };

    const handleDrop = (event) => {
      const fileItem = event.dataTransfer.files[0];
      if (!/(\.jpg|\.jpeg|\.png)$/i.exec(fileItem.name)) {
        ElNotification({
          title: "Error",
          message: "Please enter a valid file with extension image/*",
          type: "error",
        });
        return;
      }
      context.emit("file-dropped", {
        droppedFile: fileItem,
        attachmentType: props.attachmentType,
      });
      if (fileItem.type.match("image.*")) {
        getImagePreview(event.dataTransfer.files[0]);
      } else {
        alert("not an image");
      }
      file.value = fileItem;
    };

    const handleDragOver = () => {
      // console.log("dragged over");
    };

    const handleDragLeave = () => {
      // console.log("drag leave");
    };

    const removeFile = (e) => {
      context.emit("file-removed", {
        removedFile: file.value,
        attachmentType: props.attachmentType,
      });
      file.value = null;
      if (image.value) {
        URL.revokeObjectURL(image.value);
        image.value = "";
      }
    };

    return {
      handleDrop,
      handleDragOver,
      handleDragLeave,
      removeFile,
      imageClicked,
      handleFileSelected,
      viewFile,
      inputFileRef,
      file,
      image,
    };
  },
};
</script>

<style scoped lang="scss">
.edit-icon-div {
  right: 35px;
  top: 5px;
  background-color: white;
}
.delete-icon-div {
  right: 5px;
  top: 5px;
  background-color: white;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  display: block;
}
.remove-icon {
  margin-left: 0.25rem;
  color: #aac1cf;
}
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;

  .button-title {
    padding-left: 0.5rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.file-input {
  visibility: hidden;
}
</style>
