<template>
  <div>
    <div
      :class="[
        { 'one-line': isOneLine },
        { multiline: !isOneLine },
        'button-container',
        'rounded',
        'border-1',
        'border-dashed',
        'border-secondary',
        'cursor-pointer',
        'text-center',
      ]"
      :style="{ height: height }"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="left"
      data-kt-menu-flip="bottom"
    >
      <div
        class="fw-bolder fs-6 button-title d-flex align-items-center"
        style="color: #aac1cf"
      >
        <el-icon
          v-if="showRemoveIcon"
          class="el-icon--left cursor-pointer"
          @click="$emit('item-removed')"
          ><CircleCloseFilled
        /></el-icon>
        {{ highlight }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { CircleCloseFilled } from "@element-plus/icons-vue";
export default {
  name: "HighlightsText",
  components: { CircleCloseFilled },
  props: {
    highlight: {
      type: String,
      required: true,
    },
    isOneLine: {
      type: Boolean,
      default: true,
    },
    containerHeight: {
      type: Number,
      required: false,
    },
    showRemoveIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    height: function () {
      return this.containerHeight + "rem";
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;
  .button-title {
    padding-left: 0.5rem;
  }
  .plus-icon {
    margin-left: 0.75rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
