<template>
  <el-row gutter="20">
    <el-col :span="17">
      <HeroSection />
    </el-col>
    <el-col :span="7">
      <DealTerms />
    </el-col>
  </el-row>
  <el-row gutter="20">
    <el-col :span="17">
      <Highlights />
    </el-col>
    <el-col :span="7">
      <PerformanceIndicators />
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <RequiredUploads />
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <TeamMembers />
    </el-col>
  </el-row>
</template>

<script>
import HeroSection from "../components/HeroSection";
import DealTerms from "../components/DealTerms";
import Highlights from "../components/Highlights";
import PerformanceIndicators from "../components/PerformanceIndicators";
import RequiredUploads from "../components/RequiredUploads";
import TeamMembers from "../components/TeamMembers";
export default {
  name: "CreatePitch",
  components: {
    TeamMembers,
    RequiredUploads,
    PerformanceIndicators,
    Highlights,
    DealTerms,
    HeroSection,
  },
};
</script>

<style scoped></style>
