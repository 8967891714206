const HeroSectionConfig = [
  {
    attachmentType: "blackLogo",
    buttonTitle: "Favicon Upload",
    logo: "/media/svg/files/upload.svg",
    buttonHeight: 6,
  },
  {
    attachmentType: "originalLogo",
    buttonTitle: "Original Logo",
    logo: "/media/svg/files/upload.svg",
    buttonHeight: 6,
  },
  // {
  //   attachmentType: "whiteLogo",
  //   buttonTitle: "White Logo",
  //   logo: "/media/svg/files/upload.svg",
  //   buttonHeight: 6,
  // },
  // {
  //   attachmentType: "teaserImage",
  //   buttonTitle: "Teaser Image for Hero Section",
  //   description: "Drag n Drop Files here",
  //   logo: "/media/svg/files/upload.svg",
  //   buttonHeight: 20,
  //   isOneLine: false,
  // },
];

export default HeroSectionConfig;
