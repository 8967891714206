<template>
  <div>
    <div
      :class="[
        { 'one-line': isOneLine },
        { multiline: !isOneLine },
        'button-container',
        'rounded',
        'primary-transparent-background',
        'border-1',
        'border-dashed',
        'border-primary',
        'cursor-pointer',
        'text-center',
        'mb-5',
      ]"
      :style="{ height: height }"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="left"
      data-kt-menu-flip="bottom"
      :data-bs-target="triggerModal ? '#' + modalId : ''"
      :data-bs-toggle="triggerModal ? 'modal' : ''"
    >
      <span class="svg-icon svg-icon-1 svg-icon-primary plus-icon">
        <img :src="logo" alt="logo" />
      </span>
      <div class="fw-bolder fs-6 primary-color button-title">
        {{ buttonTitle }}
      </div>
      <div class="fw-bolder fs-6 button-title" style="color: #aac1cf">
        {{ description }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "AddPitchAttributesButton",
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    isOneLine: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    buttonHeight: {
      type: Number,
      required: false,
    },
    triggerModal: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: null,
    },
  },
  computed: {
    height: function () {
      return this.buttonHeight + "rem";
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;
  .button-title {
    padding-left: 0.5rem;
  }
  .plus-icon {
    margin-left: 0.75rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
