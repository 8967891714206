<template>
  <div
    class="primary-transparent-background cursor-pointer text-center position-relative"
    :style="{ height: height }"
    v-on:dragover.prevent="handleDragOver"
    v-on:drop.prevent="handleDrop"
    v-on:dragleave.prevent="handleDragLeave"
  >
    <div v-if="image" class="d-flex h-100 w-100 position-relative">
      <div
        class="position-absolute edit-icon-div cursor-pointer"
        @click="imageClicked"
      >
        <inline-svg
          class="edit-icon"
          src="media/icons/duotune/general/gen055.svg"
        />
      </div>
      <div
        class="position-absolute delete-icon-div cursor-pointer"
        @click="removeFile"
      >
        <inline-svg
          class="edit-icon"
          src="media/icons/duotune/general/gen040.svg"
        />
      </div>
      <img :src="image" class="img" alt="image" />
    </div>
    <div
      @click="imageClicked"
      v-else
      :class="[
        { 'one-line': isOneLine },
        { multiline: !isOneLine },
        'border-1',
        'border-dashed',
        'border-primary',
        'button-container',
        'rounded',
      ]"
    >
      <span class="svg-icon svg-icon-1 svg-icon-primary plus-icon">
        <img
          style="object-fit: cover"
          :src="logo"
          :class="[{ 'h-75': isOneLine, 'w-75': isOneLine }]"
          alt="logo"
        />
      </span>
      <div class="fw-bolder fs-6 primary-color button-title">
        {{ buttonTitle }}
      </div>
      <div
        class="d-flex justify-content-center align-items-center"
        style="color: #aac1cf"
      >
        <div class="fw-bolder fs-6 button-title">
          {{ file ? file.name : description }}
        </div>
      </div>
    </div>
    <input
      class="d-none"
      type="file"
      ref="inputFileRefNew"
      :accept="requiredUploadType"
      @change="handleFileSelected"
    />
  </div>
</template>

<script>
import { computed, onUnmounted, ref } from "vue";
import RequiredUploadsEnum from "../../common/helpers/RequiredUploadsEnum";
import { ElNotification } from "element-plus";

export default {
  name: "AttachmentUploadButton",
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    requiredUploadType: {
      type: String,
      required: true,
    },
    dropValidationRegex: {
      type: RegExp,
      required: true,
    },
    isOneLine: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    buttonHeight: {
      type: Number,
      required: false,
    },
    attachmentType: {
      type: String,
      required: false,
    },
    showImagePreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height: function () {
      return this.buttonHeight + "rem";
    },
  },
  setup(props, context) {
    const file = ref(null);
    const image = ref("");
    const inputFileRefNew = ref(null);

    onUnmounted(() => {
      if (image.value) {
        URL.revokeObjectURL(image.value);
      }
    });

    const getImagePreview = (file) => {
      if (image.value) {
        URL.revokeObjectURL(image.value);
      }
      image.value = URL.createObjectURL(file);
    };

    const handleFileSelected = (event) => {
      handleDrop({
        dataTransfer: {
          files: event.target.files,
        },
      });
    };

    const imageClicked = () => {
      inputFileRefNew.value.click();
    };

    const handleDrop = (event) => {
      const fileItem = event.dataTransfer.files[0];
      if (!props.dropValidationRegex.exec(fileItem.name)) {
        ElNotification({
          title: "Error",
          message:
            "Please enter a valid file with extension '" +
            props.requiredUploadType +
            "'",
          type: "error",
        });
        return;
      }
      context.emit("file-dropped", {
        droppedFile: fileItem,
        attachmentType: props.attachmentType,
      });
      if (props.showImagePreview && fileItem.type.match("image.*")) {
        getImagePreview(event.dataTransfer.files[0]);
      }
      file.value = fileItem;
    };

    const handleDragOver = () => {
      console.log("dragged over");
    };

    const handleDragLeave = () => {
      console.log("drag leave");
    };

    const removeFile = () => {
      context.emit("file-removed", {
        removedFile: file.value,
        attachmentType: props.attachmentType,
      });
      file.value = null;
      if (image.value) {
        URL.revokeObjectURL(image.value);
        image.value = "";
      }
    };

    return {
      handleDrop,
      handleDragOver,
      handleDragLeave,
      removeFile,
      imageClicked,
      handleFileSelected,
      inputFileRefNew,
      file,
      image,
    };
  },
};
</script>

<style scoped lang="scss">
.edit-icon-div {
  right: 35px;
  top: 5px;
  background-color: white;
}
.delete-icon-div {
  right: 5px;
  top: 5px;
  background-color: white;
}
.img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.remove-icon {
  margin-left: 0.25rem;
  color: #aac1cf;
}
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;

  .button-title {
    padding-left: 0.5rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.file-input {
  visibility: hidden;
}
</style>
