<template>
  <div class="add-team-member">
    <div
      class="modal fade"
      id="add-team-member-modal"
      ref="addTeamMemberModalRef"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <h2>Add Team Member</h2>
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <Form
            id="kt_modal_create_api_key_form"
            class="form"
            @submit="submit"
            :validation-schema="validationSchema"
          >
            <div class="modal-body py-10 px-lg-17">
              <div
                class="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div class="mb-5 fv-row w-100 text-center">
                  <el-avatar
                    v-on:dragover.prevent="handleDragOver"
                    v-on:drop.prevent="handleDrop"
                    v-on:dragleave.prevent="handleDragLeave"
                    class="cursor-pointer"
                    @click="handleClick"
                    :size="100"
                    :src="displayImage"
                  ></el-avatar>
                  <input
                    name="uploadAvatar"
                    @input="handleFileSelected"
                    type="file"
                    style="display: none"
                    ref="hiddenInputRef"
                  /><br />
                  <label class="fs-5 fw-bold mb-2">Photo</label>
                </div>
                <div class="d-flex flex-column mb-5 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Name</label>
                  <Field
                    type="text"
                    name="name"
                    data-placeholder="Please enter the name..."
                    class="form-control form-control-solid"
                    v-model="requestPayload.name"
                  />
                </div>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div class="d-flex flex-column mb-5 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Designation</label>
                  <Field
                    type="text"
                    name="designation"
                    data-placeholder="Please enter the name..."
                    class="form-control form-control-solid"
                    v-model="requestPayload.designation"
                  />
                </div>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="designation" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer flex-center">
              <button
                type="reset"
                id="kt_modal_create_api_key_cancel"
                class="btn btn-white me-3"
              >
                Discard
              </button>
              <button
                ref="submitButtonRef"
                type="submit"
                id="kt_modal_create_api_key_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as Yup from "yup";
import { ElNotification } from "element-plus";
import { addProjectTeamMembers } from "../../api/founder.api";
import { hideModal } from "../../core/helpers/dom";

export default {
  name: "AddProjectTeamMemberModal",
  components: {
    Form,
    ErrorMessage,
    Field,
  },
  setup(props, context) {
    const requestPayload = ref({
      name: "",
      designation: "",
      file: null,
    });
    const addTeamMemberModalRef = ref(null);
    const uploadedImage = ref(null);
    const hiddenInputRef = ref(null);
    const submitButtonRef = ref(null);

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
    });

    const handleDrop = (event) => {
      uploadedImage.value = event.dataTransfer.files[0];
      requestPayload.value.file = uploadedImage.value;
    };

    const handleDragOver = () => {
      // console.log("dragged over");
    };

    const handleDragLeave = () => {
      // console.log("drag leave");
    };

    const handleClick = () => {
      hiddenInputRef.value.click();
    };

    const handleFileSelected = (event) => {
      uploadedImage.value = event.target.files[0];
      requestPayload.value.file = uploadedImage.value;
    };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        const formData = new FormData();
        formData.append("name", requestPayload.value.name);
        formData.append("designation", requestPayload.value.designation);
        formData.append("photo", requestPayload.value.file);
        await addProjectTeamMembers(formData);
        context.emit("fetchTeamMembers");
        ElNotification({
          title: "Success",
          message: "Team Member Added.",
          type: "success",
        });
        hideModal(addTeamMemberModalRef.value);
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Team member Not added",
          type: "error",
        });
      } finally {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
      }
    };

    const displayImage = computed(() => {
      return uploadedImage.value
        ? URL.createObjectURL(uploadedImage.value)
        : "/media/avatars/blank.png";
    });
    return {
      displayImage,
      hiddenInputRef,
      requestPayload,
      validationSchema,
      submitButtonRef,
      addTeamMemberModalRef,
      handleClick,
      handleFileSelected,
      handleDrop,
      handleDragOver,
      handleDragLeave,
      submit,
    };
  },
};
</script>

<style scoped></style>
