<template>
  <ContentCard :card-height="30">
    <template v-slot:header>
      <h2 class="primary-text">Team Members</h2>
    </template>
    <template v-slot:body>
      <el-row gutter="20" class="pt-5">
        <el-col
          v-for="projectTeamMember in projectTeamMembers"
          :key="projectTeamMember.id"
          :span="6"
        >
          <TeamMemberCard
            @removeTeamMember="removeTeamMember"
            :teamMember="projectTeamMember"
          />
        </el-col>
        <el-col :span="6" v-if="projectTeamMembers.length < 4">
          <AddPitchAttributesButton
            button-title="Team Member"
            description="Click to create"
            :is-one-line="false"
            logo="/media/images/small-plus-blue.png"
            :button-height="22"
            :trigger-modal="true"
            modal-id="add-team-member-modal"
          />
        </el-col>
      </el-row>
      <AddProjectTeamMemberModal @fetchTeamMembers="fetchProjectTeamMembers" />
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import AddPitchAttributesButton from "./AddPitchAttributesButton";
import { onMounted, ref } from "vue";
import {
  getProjectTeamMembers,
  removeProjectTeamMember,
} from "../../api/founder.api";
import TeamMemberCard from "./TeamMemberCard";
import AddProjectTeamMemberModal from "./AddProjectTeamMemberModal";
import { ElNotification } from "element-plus";
export default {
  name: "TeamMembers",
  components: {
    AddProjectTeamMemberModal,
    TeamMemberCard,
    AddPitchAttributesButton,
    ContentCard,
  },
  setup() {
    const projectTeamMembers = ref([]);

    onMounted(async () => {
      await fetchProjectTeamMembers();
    });

    const removeTeamMember = async (teamMember) => {
      const index = projectTeamMembers.value.indexOf(teamMember);
      projectTeamMembers.value.splice(index, 1);
      try {
        await removeProjectTeamMember(teamMember.id);
        ElNotification({
          title: "Success",
          message: "Team member removed.",
          type: "success",
        });
      } catch (e) {
        projectTeamMembers.value.splice(index, 0, teamMember);
        ElNotification({
          title: "Error",
          message: "Team member Not removed",
          type: "error",
        });
      }
    };

    const fetchProjectTeamMembers = async () => {
      const { data } = await getProjectTeamMembers();
      projectTeamMembers.value = data.data;
    };
    return {
      projectTeamMembers,
      fetchProjectTeamMembers,
      removeTeamMember,
    };
  },
};
</script>

<style scoped></style>
