<template>
  <div
    class="team-member d-flex flex-column justify-content-center align-items-center border-1 border-dashed border-primary mb-5 rounded position-relative"
  >
    <el-avatar
      :size="100"
      :src="teamMember.photo ? teamMember.photo : '/media/avatars/blank.png'"
    ></el-avatar>
    <div class="fw-bolder fs-6 primary-color button-title">
      {{ teamMember.name }}
    </div>
    <div class="fw-bolder fs-6 button-title" style="color: #aac1cf">
      {{ teamMember.designation }}
    </div>
    <el-icon
      @click="$emit('removeTeamMember', teamMember)"
      class="el-icon--left cursor-pointer remove-icon position-absolute"
      ><CircleCloseFilled
    /></el-icon>
  </div>
</template>
<script>
import { CircleCloseFilled } from "@element-plus/icons-vue";
export default {
  name: "TeamMemberCard",
  components: { CircleCloseFilled },
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.team-member {
  height: 22rem;
  .el-icon--left {
    top: 5px;
    right: 0;
  }
  .remove-icon {
    margin-left: 0.25rem;
    color: #aac1cf;
  }
}
</style>
