<template>
  <ContentCard :card-height="44">
    <template v-slot:header>
      <h2 class="primary-text">Key Performance Indicators</h2>
    </template>
    <template v-slot:body>
      <div v-loading="loading" class="pt-5">
        <HighlightsText
          v-for="(performanceIndicator, index) in projectPerformanceIndicators"
          :key="index"
          :container-height="6"
          :highlight="performanceIndicator.performanceIndicator.name"
          class="mb-5"
          @item-removed="onRemovePerformanceIndicator(performanceIndicator.id)"
        />
        <AddPitchAttributesButton
          v-if="projectPerformanceIndicators.length < 5"
          button-title="Select Indicator"
          logo="/media/images/small-plus-blue.png"
          :button-height="6"
        >
          <EditProfileDropdown
            :selected-items="disabledPerformanceIndicators"
            :dropdown-menu-items="allPerformanceIndicators"
            @item-clicked="onSelectPerformanceIndicator"
          />
        </AddPitchAttributesButton>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import AddPitchAttributesButton from "./AddPitchAttributesButton";
import { computed, onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  addPerformanceIndicatorToProject,
  getAllPerformanceIndicators,
  getPerformanceIndicatorsOfLoggedInFounder,
  removePerformanceIndicatorFromProject,
} from "../../api/founder.api";
import HighlightsText from "./HighlightsText";
import EditProfileDropdown from "../../expert/components/EditProfileDropdown";
import { MenuComponent } from "../../assets/ts/components";
export default {
  name: "PerformanceIndicators",
  components: {
    EditProfileDropdown,
    HighlightsText,
    ContentCard,
    AddPitchAttributesButton,
  },
  setup() {
    const allPerformanceIndicators = ref([]);
    const projectPerformanceIndicators = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      try {
        await fetchAllPerformanceIndicators();
        await fetchProjectPerformanceIndicators();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to get data",
          type: "error",
        });
      }
    });

    const disabledPerformanceIndicators = computed(() => {
      const selected = [];
      projectPerformanceIndicators.value.forEach((object) => {
        selected.push(object.performanceIndicator);
      });
      return selected;
    });

    const fetchAllPerformanceIndicators = async () => {
      const { data } = await getAllPerformanceIndicators();
      allPerformanceIndicators.value = data.data;
    };

    const fetchProjectPerformanceIndicators = async () => {
      const { data } = await getPerformanceIndicatorsOfLoggedInFounder();
      projectPerformanceIndicators.value = data.data;
    };

    const onSelectPerformanceIndicator = async (performanceIndicator) => {
      try {
        loading.value = true;
        await addPerformanceIndicatorToProject(performanceIndicator.id);
        await fetchProjectPerformanceIndicators();
        ElNotification({
          title: "Success",
          message: "Performance indicator updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Performance indicator not updated.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onRemovePerformanceIndicator = async (performanceIndicatorId) => {
      try {
        loading.value = true;
        await removePerformanceIndicatorFromProject(performanceIndicatorId);
        await fetchProjectPerformanceIndicators();
        ElNotification({
          title: "Success",
          message: "Performance indicator removed.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Performance indicator not removed.",
          type: "error",
        });
      } finally {
        if (projectPerformanceIndicators.value.length < 5) {
          MenuComponent.reinitialization();
        }
        loading.value = false;
      }
    };

    return {
      allPerformanceIndicators,
      projectPerformanceIndicators,
      loading,
      disabledPerformanceIndicators,
      onSelectPerformanceIndicator,
      onRemovePerformanceIndicator,
    };
  },
};
</script>

<style scoped></style>
