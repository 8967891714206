<template>
  <ContentCard :card-height="30">
    <template v-slot:header>
      <h2 class="primary-text">Required Uploads</h2>
      <el-button
        :loading="savingChanges"
        v-if="files.length"
        size="mini"
        style="margin-left: 0.5rem"
        class="btn btn-success btn-sm mb-5 mt-3"
        @click="onSaveChanges"
      >
        Save Changes
      </el-button>
    </template>
    <template v-slot:body>
      <el-row gutter="20" class="pt-5" v-loading="deleteFileLoading">
        <el-col :span="6">
          <AttachmentUploadButton
            v-if="!requiredUploads.venturePitch"
            :drop-validation-regex="/(\.pdf)$/i"
            required-upload-type=".pdf"
            button-title="Venture Pitch"
            description="Drag n Drop Files here"
            logo="/media/svg/files/upload.svg"
            :button-height="22"
            :is-one-line="false"
            attachment-type="venturePitch"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
          />
          <AttachmentPreview
            v-else
            :container-height="22"
            file-name="Venture Pitch"
            :firebase-url="requiredUploads.venturePitch"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
            attachment-type="venturePitch"
          />
        </el-col>
        <el-col :span="6">
          <AttachmentUploadButton
            required-upload-type=".pdf"
            :drop-validation-regex="/(\.pdf)$/i"
            v-if="!requiredUploads.financialPlan"
            button-title="Your Financial Plan"
            description="Drag n Drop Files here"
            logo="/media/svg/files/upload.svg"
            :button-height="22"
            :is-one-line="false"
            attachment-type="financialPlan"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
          />
          <AttachmentPreview
            v-else
            :container-height="22"
            file-name="Financial Plan"
            :firebase-url="requiredUploads.financialPlan"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
            attachment-type="financialPlan"
          />
        </el-col>
        <el-col :span="6">
          <AttachmentUploadButton
            required-upload-type=".xlsx, .xls, .csv"
            :drop-validation-regex="/(\.csv|\.xlsx|\.xls)$/i"
            v-if="!requiredUploads.dataRequest"
            button-title="Data Request"
            description="Drag n Drop Files here"
            logo="/media/svg/files/upload.svg"
            :button-height="22"
            :is-one-line="false"
            attachment-type="dataRequest"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
          />
          <AttachmentPreview
            v-else
            :container-height="22"
            file-name="Data Request"
            :firebase-url="requiredUploads.dataRequest"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
            attachment-type="dataRequest"
          />
        </el-col>
        <el-col :span="6">
          <AttachmentUploadButton
            required-upload-type=".xlsx, .xls, .csv"
            :drop-validation-regex="/(\.csv|\.xlsx|\.xls)$/i"
            v-if="!requiredUploads.sevenFoundersForecast"
            button-title="7Founders Forecast"
            description="Drag n Drop Files here"
            logo="/media/svg/files/upload.svg"
            :button-height="22"
            :is-one-line="false"
            attachment-type="sevenFoundersForecast"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
          />
          <AttachmentPreview
            v-else
            :container-height="22"
            file-name="Seven Founders Forecast"
            :firebase-url="requiredUploads.sevenFoundersForecast"
            @file-dropped="onFileDropped"
            @file-removed="onFileRemoved"
            attachment-type="sevenFoundersForecast"
          />
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import AttachmentUploadButton from "./AttachmentUploadButton";
import ContentCard from "../../common/components/ContentCard";
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  deleteLogo,
  deleteProjectUpload,
  getProjectUploads,
  uploadProjectUploads,
} from "../../api/founder.api";
import AttachmentPreview from "./AttachmentPreview";
export default {
  name: "RequiredUploads",
  components: { AttachmentPreview, ContentCard, AttachmentUploadButton },
  setup() {
    const requiredUploads = ref({
      venturePitch: null,
      financialPlan: null,
      dataRequest: null,
      sevenFoundersForecast: null,
    });
    const files = ref([]);
    const deleteFileLoading = ref(false);
    const savingChanges = ref(false);

    onMounted(async () => {
      try {
        await fetchRequiredUploads();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to get the attachments.",
          type: "error",
        });
      }
    });
    const onFileDropped = (payload) => {
      const existingFileIndex = files.value.findIndex(
        (currentFile) => currentFile.attachmentType === payload.attachmentType
      );
      if (existingFileIndex === -1) {
        files.value.push(payload);
      } else {
        files.value[existingFileIndex].droppedFile = payload.droppedFile;
      }
    };

    const onFileRemoved = async (payload) => {
      files.value = files.value.filter(
        (currentFile) => currentFile.attachmentType !== payload.attachmentType
      );
      if (requiredUploads.value[payload.attachmentType]) {
        deleteFileLoading.value = true;
        await deleteProjectUpload(payload.attachmentType);
        await fetchRequiredUploads();
        deleteFileLoading.value = false;
      }
    };

    const fetchRequiredUploads = async () => {
      const { data } = await getProjectUploads();
      requiredUploads.value.dataRequest = data.data.dataRequest;
      requiredUploads.value.financialPlan = data.data.financialPlan;
      requiredUploads.value.venturePitch = data.data.venturePitch;
      requiredUploads.value.sevenFoundersForecast =
        data.data.sevenFoundersForecast;
    };

    const onSaveChanges = async () => {
      try {
        savingChanges.value = true;
        let formData = new FormData();
        for (let i = 0; i < files.value.length; i++) {
          formData.append(
            files.value[i].attachmentType,
            files.value[i].droppedFile
          );
        }
        await uploadProjectUploads(formData);
        await fetchRequiredUploads();
        files.value = [];
        ElNotification({
          title: "Success",
          message: "Attachments Uploaded.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to upload attachments.",
          type: "error",
        });
      } finally {
        savingChanges.value = false;
      }
    };

    return {
      requiredUploads,
      files,
      savingChanges,
      deleteFileLoading,
      onFileDropped,
      onFileRemoved,
      onSaveChanges,
    };
  },
};
</script>

<style scoped></style>
