<template>
  <div
    class="primary-transparent-background text-center position-relative"
    :style="{ height: height }"
    v-on:dragover.prevent="handleDragOver"
    v-on:drop.prevent="handleDrop"
    v-on:dragleave.prevent="handleDragLeave"
  >
    <div
      :class="[
        'multiline',
        'border-1',
        'border-dashed',
        'border-primary',
        'button-container',
        'rounded',
      ]"
    >
      <div
        class="position-absolute edit-icon-div cursor-pointer"
        @click="imageClicked"
      >
        <inline-svg src="media/icons/duotune/general/gen055.svg" />
      </div>
      <div
        class="position-absolute delete-icon-div cursor-pointer"
        @click="removeFile"
      >
        <inline-svg src="media/icons/duotune/general/gen040.svg" />
      </div>
      <span class="svg-icon svg-icon-1 svg-icon-primary plus-icon">
        <img :src="getFileTypeUrlFromFile(attachmentType)" alt="logo" />
      </span>
      <div
        @click="viewFile"
        class="fw-bolder cursor-pointer fs-6 primary-color button-title"
      >
        {{ fileName }}
      </div>
      <div
        class="d-flex justify-content-center align-items-center"
        style="color: #aac1cf"
      >
        <div class="fw-bolder fs-6 button-title">
          {{ file ? file.name : description }}
        </div>
      </div>
    </div>
    <input
      class="d-none"
      type="file"
      ref="inputFileRef"
      :accept="requiredUploadType"
      @change="handleFileSelected"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { getFileTypeUrlFromFile } from "../../common/helpers/helpers";
import RequiredUploadsEnum from "../../common/helpers/RequiredUploadsEnum";
import { ElNotification } from "element-plus";

export default {
  name: "AttachmentPreview",
  props: {
    fileName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    containerHeight: {
      type: Number,
      required: false,
    },
    attachmentType: {
      type: String,
      required: false,
    },
    firebaseUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    height: function () {
      return this.containerHeight + "rem";
    },
    attachmentLogo: function () {
      if (this.firebaseUrl.includes("pdf")) {
        return "/media/svg/files/pdf.svg";
      } else if (this.firebaseUrl.includes("doc")) {
        return "/media/svg/files/doc.svg";
      } else {
        return "/media/svg/files/folder-document.svg";
      }
    },
  },
  setup(props, context) {
    const file = ref(null);
    const inputFileRef = ref(null);

    const allowedExtention = computed(() => {
      if (
        props.attachmentType === RequiredUploadsEnum.VENTURE_PITCH ||
        props.attachmentType === RequiredUploadsEnum.FINANCIAL_PLAN
      ) {
        return /(\.pdf)$/i;
      } else {
        return /(\.csv|\.xlsx|\.xls)$/i;
      }
    });

    const requiredUploadType = computed(() => {
      if (
        props.attachmentType === RequiredUploadsEnum.VENTURE_PITCH ||
        props.attachmentType === RequiredUploadsEnum.FINANCIAL_PLAN
      ) {
        return ".pdf";
      } else {
        return ".xlsx, .xls, .csv";
      }
    });

    const handleDrop = (event) => {
      const fileItem = event.dataTransfer.files[0];
      if (!allowedExtention.value.exec(fileItem.name)) {
        ElNotification({
          title: "Error",
          message:
            "Please enter a valid file with extension '" +
            requiredUploadType.value +
            "'",
          type: "error",
        });
        return;
      }
      context.emit("file-dropped", {
        droppedFile: fileItem,
        attachmentType: props.attachmentType,
      });
      file.value = fileItem;
    };

    const handleDragOver = () => {
      console.log("dragged over");
    };

    const handleDragLeave = () => {
      console.log("drag leave");
    };

    const viewFile = () => {
      if (props.firebaseUrl) {
        window.open(props.firebaseUrl, "_blank");
      }
    };

    const removeFile = () => {
      context.emit("file-removed", {
        removedFile: file.value,
        attachmentType: props.attachmentType,
      });
      file.value = null;
    };

    const imageClicked = () => {
      inputFileRef.value.click();
    };

    const handleFileSelected = (event) => {
      handleDrop({
        dataTransfer: {
          files: event.target.files,
        },
      });
    };

    watch(
      () => props.firebaseUrl,
      () => {
        file.value = null;
      }
    );

    return {
      handleDrop,
      handleDragOver,
      handleDragLeave,
      removeFile,
      imageClicked,
      handleFileSelected,
      viewFile,
      getFileTypeUrlFromFile,
      requiredUploadType,
      inputFileRef,
      file,
    };
  },
};
</script>

<style scoped lang="scss">
.edit-icon-div {
  right: 35px;
  top: 5px;
  //background-color: white;
}
.delete-icon-div {
  right: 5px;
  top: 5px;
  //background-color: white;
}
.img {
  width: 100%;
  height: 100%;
  display: block;
}
.remove-icon {
  margin-left: 0.25rem;
  color: #aac1cf;
}
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;

  .button-title {
    padding-left: 0.5rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.file-input {
  visibility: hidden;
}
</style>
