<template>
  <ContentCard :card-height="44">
    <template v-slot:header>
      <h2 class="primary-text">Highlights</h2>
    </template>
    <template v-slot:body>
      <div v-loading="loading" class="pt-5">
        <HighlightsText
          v-for="highlight in highlights"
          :key="highlight.id"
          :highlight="highlight.value"
          :container-height="5"
          class="mb-5"
          @item-removed="onRemoveHighlight(highlight.id)"
        />
        <AddPitchAttributesButton
          v-show="highlights.length < 5"
          button-title="Highlight"
          logo="/media/images/small-plus-blue.png"
          :button-height="5"
          modal-id="highlightsModal"
          :trigger-modal="true"
        >
          <AddCompanyModal
            ref="addHighLightModalRef"
            modal-id="highlightsModal"
            title="Add Highlight"
          >
            <Form
              id="kt_modal_create_api_key_form"
              class="form"
              @submit.prevent="submit"
              :validation-schema="validationSchema"
            >
              <div class="modal-body py-10 px-lg-17">
                <div
                  class="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  <div class="mb-5 fv-row">
                    <label class="required fs-5 fw-bold mb-2">Highlight</label>
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="Highlight"
                      name="highlight..."
                      v-model="highlightText"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="highlight" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer flex-center">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-white me-3"
                >
                  Discard
                </button>
                <button
                  ref="submitButtonRef"
                  type="submit"
                  id="kt_modal_create_api_key_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </Form>
          </AddCompanyModal>
        </AddPitchAttributesButton>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import AddPitchAttributesButton from "./AddPitchAttributesButton";
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  createProjectHighlight,
  getProjectHighlights,
  removeProjectHighlight,
} from "../../api/founder.api";
import HighlightsText from "./HighlightsText";
import { MenuComponent } from "../../assets/ts/components";
import AddCompanyModal from "../../expert/components/AddCompanyModal";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "Highlights",
  components: {
    HighlightsText,
    AddPitchAttributesButton,
    ContentCard,
    AddCompanyModal,
    Field,
    ErrorMessage,
  },
  setup() {
    const highlights = ref([]);
    const submitButtonRef = ref(null);
    const highlightText = ref("");
    const addHighLightModalRef = ref(null);
    const loading = ref(false);
    const validationSchema = Yup.object().shape({
      highlight: Yup.string().required().label("highlight"),
    });

    onMounted(async () => {
      try {
        await fetchProjectHighlights();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to get highlights",
          type: "error",
        });
      }
    });

    const fetchProjectHighlights = async () => {
      const { data } = await getProjectHighlights();
      highlights.value = data.data;
    };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        await createProjectHighlight(highlightText.value);
        await fetchProjectHighlights();
        ElNotification({
          title: "Success",
          message: "Highlights Updated.",
          type: "success",
        });
        // MenuComponent.hideDropdowns();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Rates Not Updated",
          type: "error",
        });
      } finally {
        highlightText.value = "";
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
        addHighLightModalRef.value.modalHide();
      }
    };

    const onRemoveHighlight = async (highlightId) => {
      try {
        loading.value = true;
        await removeProjectHighlight(highlightId);
        await fetchProjectHighlights();
        ElNotification({
          title: "Success",
          message: "Project Highlight Removed.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Project Highlight not removed.",
          type: "error",
        });
      } finally {
        if (highlights.value.length < 5) {
          MenuComponent.reinitialization();
        }
        loading.value = false;
      }
    };

    return {
      highlights,
      submitButtonRef,
      highlightText,
      addHighLightModalRef,
      submit,
      onRemoveHighlight,
      loading,
      validationSchema,
    };
  },
};
</script>

<style scoped></style>
