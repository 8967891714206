<template>
  <ContentCard :card-height="37">
    <template v-slot:header>
      <div
        class="w-100 d-flex flex-row align-items-center justify-content-between"
      >
        <div>
          <h2 class="primary-text">Hero Section</h2>
        </div>
        <div>
          <el-button
            :loading="savingChanges"
            v-if="files.length"
            size="mini"
            style="margin-left: 0.5rem"
            class="btn btn-success btn-sm mb-5 mt-3 float-end"
            @click="onSaveChanges"
          >
            Save Changes
          </el-button>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div v-loading="deleteFileLoading">
        <el-row gutter="20" v-if="!loading">
          <el-col
            :span="12"
            v-for="(item, index) in HeroSectionConfig"
            :key="index"
          >
            <LogoImage
              v-if="heroSectionLogos[item.attachmentType]"
              :image-url="heroSectionLogos[item.attachmentType]"
              :button-height="item.buttonHeight"
              :attachment-type="item.attachmentType"
              @file-dropped="onFileDropped"
              @file-removed="onFileRemoved"
            />
            <AttachmentUploadButton
              v-else
              :drop-validation-regex="/(\.jpg|\.jpeg|\.png)$/i"
              required-upload-type="image/*"
              :logo="item.logo"
              :button-title="item.buttonTitle"
              :attachment-type="item.attachmentType"
              :button-height="item.buttonHeight"
              @file-dropped="onFileDropped"
              @file-removed="onFileRemoved"
              :show-image-preview="true"
            />
          </el-col>
        </el-row>
        <el-row class="mt-7" v-if="!loading">
          <el-col :span="24">
            <LogoImage
              v-if="heroSectionLogos.teaserImage"
              :image-url="heroSectionLogos.teaserImage"
              :button-height="20"
              attachment-type="teaserImage"
              @file-dropped="onFileDropped"
              @file-removed="onFileRemoved"
            />
            <AttachmentUploadButton
              v-else
              :drop-validation-regex="/(\.jpg|\.jpeg|\.png)$/i"
              required-upload-type="image/*"
              attachment-type="teaserImage"
              logo="/media/svg/files/upload.svg"
              button-title="Teaser Image for Hero Section"
              description="Drag n Drop Files here"
              :button-height="20"
              :is-one-line="false"
              @file-dropped="onFileDropped"
              @file-removed="onFileRemoved"
              :show-image-preview="true"
            />
          </el-col>
        </el-row>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import AttachmentUploadButton from "./AttachmentUploadButton";
import { onMounted, ref } from "vue";
import { deleteLogo, getLogos, uploadLogo } from "../../api/founder.api";
import HeroSectionConfig from "../config/HeroSectionConfig";
import { ElNotification } from "element-plus";
import LogoImage from "./LogoImage";
export default {
  name: "HeroSection",
  components: { LogoImage, AttachmentUploadButton, ContentCard },
  setup() {
    const files = ref([]);
    const loading = ref(true);
    const deleteFileLoading = ref(false);
    const savingChanges = ref(false);
    const heroSectionLogos = ref({
      originalLogo: "",
      blackLogo: "",
      whiteLogo: "",
      teaserImage: "",
    });

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchHeroSectionLogos();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Can't get logos.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    const fetchHeroSectionLogos = async () => {
      const { data } = await getLogos();
      heroSectionLogos.value.originalLogo = data.data.originalLogo;
      heroSectionLogos.value.blackLogo = data.data.blackLogo;
      heroSectionLogos.value.whiteLogo = data.data.whiteLogo;
      heroSectionLogos.value.teaserImage = data.data.teaserImage;
    };

    const onFileDropped = (payload) => {
      const existingFileIndex = files.value.findIndex(
        (currentFile) => currentFile.attachmentType === payload.attachmentType
      );
      if (existingFileIndex === -1) {
        files.value.push(payload);
      } else {
        files.value[existingFileIndex].droppedFile = payload.droppedFile;
      }
    };

    const onFileRemoved = async (payload) => {
      files.value = files.value.filter(
        (currentFile) => currentFile.attachmentType !== payload.attachmentType
      );
      if (heroSectionLogos.value[payload.attachmentType]) {
        deleteFileLoading.value = true;
        await deleteLogo(payload.attachmentType);
        await fetchHeroSectionLogos();
        deleteFileLoading.value = false;
      }
    };

    const onSaveChanges = async () => {
      try {
        savingChanges.value = true;
        let formData = new FormData();
        for (let i = 0; i < files.value.length; i++) {
          formData.append(
            files.value[i].attachmentType,
            files.value[i].droppedFile
          );
        }
        await uploadLogo(formData);
        await fetchHeroSectionLogos();
        files.value = [];
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Unable to upload logos.",
          type: "error",
        });
      } finally {
        savingChanges.value = false;
        ElNotification({
          title: "Success",
          message: "Logos Uploaded.",
          type: "success",
        });
      }
    };

    return {
      files,
      onFileDropped,
      onFileRemoved,
      onSaveChanges,
      deleteFileLoading,
      loading,
      heroSectionLogos,
      savingChanges,
      HeroSectionConfig,
    };
  },
};
</script>

<style scoped></style>
